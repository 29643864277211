import { Controller } from 'stimulus';

export default class JobPostingsController extends Controller {
  goToJob() {
    const href = this.data.get('url');

    if (href) {
      window.location.href = href;
    }
  }

  hoverButton(e) {
    const button = e.target.querySelector('a');
    if (button) {
      button.classList.toggle('buttonThemed');
      button.classList.toggle('buttonSecondary');
    }
  }

  unhoverButton(e) {
    const button = e.target.querySelector('a');
    if (button) {
      button.classList.toggle('buttonThemed');
      button.classList.toggle('buttonSecondary');
    }
  }
}
