import { Controller } from 'stimulus';

function buildFrame() {
  var frameWrapper = document.createElement('div');
  frameWrapper.classList.add('tingle-modal-box__iframe-wrapper');
  var frame = document.createElement('iframe');
  frameWrapper.appendChild(frame);

  frame.setAttribute('src', 'http://google.com/');
  frame.classList.add('tingle-modal-box__iframe');
  frame.width = 750;
  frame.height = 420;
  frame.frameborder = 0;
  return [frameWrapper, frame];
}

function buildModal(iframe, url) {
  return new tingle.modal({
    onOpen: () => {
      iframe.src = url;
    },
    onClose: () => {
      iframe.src = null;
    },
    closeLabel: 'Close',
  });
}
export default class VideoPlayController extends Controller {
  play() {
    var url = this.data.get('iframeUrl');
    this.iframe = this.iframe || buildFrame();
    var [wrapper, iframe] = this.iframe;
    this.modal = this.modal || buildModal(iframe, url);
    this.modal.setContent(wrapper);
    this.modal.open();
  }
}
