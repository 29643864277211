import { Controller } from 'stimulus'

export default class UploaderController extends Controller {
  static targets = ['input', 'label', 'selectedFile', 'selectedFileContent', 'hiddenBlobNamesInput']

  connect() {
    this.fileSelected()
  }

  fileSelected() {
    const names = this.getNames()

    if (names) {
      this.selectedFileContentTarget.textContent = names
      this.labelTarget.classList.toggle('hidden')
      this.selectedFileTarget.classList.toggle('hidden')
    }
  }

  getFiles() {
    return this.inputTarget.files
  }

  getBlobNames() {
    return (
      this.hiddenBlobNamesInputTarget.value && JSON.parse(this.hiddenBlobNamesInputTarget.value)
    )
  }

  clearInput() {
    const files = this.getFiles()
    const blobNames = this.getBlobNames()

    if (files.length) {
      this.clearFilesInput()
    } else if (blobNames.length) {
      this.clearBlobInput()
    }
    const blobIdsNode = document.getElementById('blob_ids')
    blobIdsNode.value = null
  }

  clearFilesInput() {
    this.inputTarget.files = null
    this.inputTarget.value = null
    this.toggleInputClass()
  }

  clearBlobInput() {
    this.hiddenBlobNamesInputTarget.value = null
    this.toggleInputClass()
  }

  toggleInputClass() {
    this.labelTarget.classList.toggle('hidden')
    this.selectedFileTarget.classList.toggle('hidden')
  }

  getNames() {
    const files = this.getFiles()
    const blobNames = this.getBlobNames()
    if (files.length) {
      return this.getFileNamesList(files)
    } else if (blobNames.length) {
      return this.getBlobNamesList(blobNames)
    }
  }

  getFileNames(files) {
    return [...files].map((file) => file.name)
  }

  getFileNamesList(files) {
    const fileNames = this.getFileNames(files)
    return this.generateNamesList(fileNames)
  }

  getBlobNamesList(blobNames) {
    return this.generateNamesList(blobNames)
  }

  generateNamesList(namesList) {
    if (namesList.length === 1) return namesList[0]
    return namesList.map((name) => `<li>${name}</li>`).join('')
  }
}
