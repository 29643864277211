import { Controller } from 'stimulus';

function documentScrollTop() {
  return window.pageYOffset || document.documentElement.scrollTop;
}
function getDocumentHeight() {
  var body = document.body;
  var html = document.documentElement;
  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
}

var DELTA = 5;
export default class NavController extends Controller {
  static targets = ['header', 'hamburger'];
  connect() {
    this.lastScrollTop = 0;
    this.setScrollInterval();

    window.addEventListener('scroll', () => (this.didScroll = true));
  }

  disconnect() {
    clearInterval(this.scrollInterval);
  }

  setScrollInterval() {
    var self = this;
    this.scrollInterval = setInterval(() => {
      if (self.didScroll) {
        self.manageScroll();
        self.didScroll = false;
      }
    }, 250);
  }

  manageScroll() {
    this.navHeight = this.navHeight || this.headerTarget.offsetHeight;
    var scrollTop = documentScrollTop();
    var isScrolled = scrollTop > 0;

    this.headerTarget.classList.toggle('topNav--scrolled', isScrolled);
    var bodyPadding = isScrolled ? this.navHeight + 'px' : '0px';
    document.body.style.paddingTop = bodyPadding;

    if (Math.abs(this.lastScrollTop - scrollTop) <= DELTA) return;

    if (scrollTop > this.lastScrollTop && scrollTop > this.navHeight) {
      // Scroll Down
      this.headerTarget.classList.add('topNav--scrollingDown');
    } else {
      // Scroll Up
      // If did not scroll past the document (possible on mac)...
      var documentHeight = getDocumentHeight();
      if (scrollTop + window.innerHeight < documentHeight) {
        this.headerTarget.classList.remove('topNav--scrollingDown');
      }
    }

    this.lastScrollTop = scrollTop;
  }

  toggleMobileMenu() {
    this.hamburgerTarget.classList.toggle('hamburger--open');
    document.body.classList.toggle('mobile-menu-open');
  }
}
