import { Controller } from 'stimulus';

export default class TabsController extends Controller {
  static targets = ['wrapper', 'buttonLabel', 'dropdown', 'anchor', 'panel'];

  changePanel(tabId) {
    this.panelTargets.forEach((e) => e.classList.remove('active'));
    var currentPanel = this.panelTargets.find((e) => e.dataset.tabId === tabId);
    currentPanel.classList.add('active');
  }

  filterPanel(tabId, noFilter) {
    this.panelTargets.forEach((panel) => {
      var tabs = JSON.parse(panel.dataset.tabId);
      if (tabs.includes(tabId) || noFilter) {
        panel.classList.remove('js-hide');
      } else {
        panel.classList.add('js-hide');
      }
    });
  }

  toggleDropdown() {
    // Mobile menu
    this.wrapperTarget.classList.toggle('tabs--open');
  }

  changeTab(event) {
    event.preventDefault();

    var navActiveClass = 'tabs__navItem--active';
    // Change active Tab anchor
    this.anchorTargets.forEach((e) => e.classList.remove(navActiveClass));
    var currentTab = this.anchorTargets.find((e) => e === event.target);
    var tabId = currentTab.dataset.tabId;
    var noFilter = currentTab.dataset.noFilter;

    currentTab.classList.add(navActiveClass);

    // Set label text on change
    this.buttonLabelTarget.innerText = currentTab.innerText;

    var mode = this.data.get('mode');
    if (mode === 'panel') {
      this.changePanel(tabId);
    } else if (mode === 'filter') {
      this.filterPanel(tabId, !!noFilter);
    }

    this.toggleDropdown();
  }

  toggleMenu() {
    this.toggleDropdown();
  }
}
