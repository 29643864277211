import { Controller } from 'stimulus'

const UNPROCESSABLE_ENTITY = 422

export default class OpenApplicationFormController extends Controller {
  static targets = ['submit']

  submit(event) {
    event.preventDefault()
    const data = new FormData(event.target)

    this._hideErrors()
    this.submitTarget.disabled = true

    this._createApplication(data)
      .then((data) => this._onSuccess(data))
      .catch((error) => this._onError(error))
  }

  _createApplication(body) {
    const endpoint = window.location.href
    return fetch(endpoint, { method: 'POST', body }).then((res) => {
      return res
        .json()
        .then((data) => {
          res.data = data
        })
        .catch(Object)
        .then(() => (res.ok ? res : Promise.reject(res)))
    })
  }

  _onSuccess(response) {
    window.location.href = response.data.redirect_url
  }

  _onError(response) {
    this.submitTarget.disabled = false

    if (response.status === UNPROCESSABLE_ENTITY) {
      this._handleValidationErrors(response.data)
      return
    }

    this._showFlashError()
  }

  _hideErrors() {
    $('[data-error-field]').addClass('hidden')
    $('#flash-container').addClass('hidden')
  }

  _handleValidationErrors(errors) {
    for (let field in errors) {
      $(`[data-error-field="${field}"]`).removeClass('hidden').html(errors[field][0])
    }
  }

  _showFlashError() {
    $('#flash-container').removeClass('hidden')
  }
}
