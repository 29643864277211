import '../../src/css/pages/companyPages.scss'

import '../../src/js/runtime'
import startStimulus from '../../src/js/startStimulus'

import nav from '../../src/js/controllers/nav_controller'
import videoImageLoader from '../../src/js/controllers/video_image_loader_controller'
import videoPlay from '../../src/js/controllers/video_play_controller'
import tabs from '../../src/js/controllers/tabs_controller'
import jobPostings from '../../src/js/controllers/job_postings_controller'
import applicationForm from '../../src/js/controllers/application_form_controller'
import openApplicationForm from '../../src/js/controllers/open_application_form_controller'
import uploader from '../../src/js/controllers/uploader_controller'
import flash from '../../src/js/controllers/flash_controller'

startStimulus([
  { nav },
  { flash },
  { 'video-image-loader': videoImageLoader },
  { 'video-play': videoPlay },
  { tabs },
  { 'job-postings': jobPostings },
  { 'application-form': applicationForm },
  { 'open-application-form': openApplicationForm },
  { uploader: uploader }
])

if (!!document.referrer && document.referrer.includes('indeed')) {
  const url = new URL(window.location)
  if (!url.searchParams.get('utm_source')) {
    url.searchParams.set('utm_source', 'indeed')
    window.location = url
  }
}
