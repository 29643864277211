import { Controller } from 'stimulus';

function loadVimeoThumbnail(image, videoId, fallbackUrl) {
  var xhr = new XMLHttpRequest();

  xhr.open('GET', 'https://vimeo.com/api/v2/video/' + videoId + '.json', true);
  xhr.onload = () => {
    if (xhr.readyState === 4 && xhr.status === 200) {
      var data = xhr.responseText;
      var parsedData = JSON.parse(data);
      // replace 640 with 1280x720 to get a bigger thumbnail
      image.src = parsedData[0].thumbnail_large.replace('-d_640', '-d_1280x720');
    } else {
      image.src = fallbackUrl;
    }
  };
  xhr.onerror = function () {
    console.error(xhr.statusText);
  };
  xhr.send(null);
}

export default class VideoImageLoaderController extends Controller {
  static targets = ['image'];

  connect() {
    if (this.data.get('provider') !== 'vimeo') return;

    var image = this.imageTarget;
    var fallbackImageURL = this.data.get('fallback');
    var videoId = this.data.get('videoId');
    loadVimeoThumbnail(image, videoId, fallbackImageURL);
  }
}
