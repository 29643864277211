import { Application } from 'stimulus'
//import { definitionsFromContext } from 'stimulus/webpack-helpers'

function registerControllerBuilder(application) {
  return function (controller) {
    const key = Object.keys(controller)[0]
    const component = Object.values(controller)[0]
    application.register(key, component)
  }
}

export default function startStimulus(controllers) {
  // Wait for jQuery
  $(function () {
    const application = Application.start()
    const register = registerControllerBuilder(application)
    controllers.forEach(function (controller) {
      register(controller)
    })
  })
}
